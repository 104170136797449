import React, { useState, useEffect } from "react"
import tw from "twin.macro"

import QuizForm from "./QuizForm"
import QuizResult from "./QuizResult"
import QuizSendResultsForm from "./QuizSendResultsForm"
import QuizThankYou from "./QuizThankYou"
import { scrollTo, isBrowser } from "@components/utils"

const Quiz = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [submittedData, setSubmittedData] = useState(null)
  const [communication, setCommunication] = useState(false)

  const goToPage = goToIndex => {
    setCurrentPage(goToIndex)
    const scrollToTopOfQuizEl = document.querySelector("#quiz")
    scrollTo(scrollToTopOfQuizEl)
  }

  useEffect(() => {
    // Screenshots
    if (isBrowser) {
      const isEDMode = window.location.href.indexOf("ed=1") > -1
      if (isEDMode) {
        for (let i = 0; i <= 7; i++) {
          if (window.location.search.indexOf(`page${i}`) > -1) {
            setCurrentPage(i)
          }
        }
      }
    }
  }, [])

  return (
    <>
      {currentPage < data.length ? (
        <div
          css={[
            tw`relative flex flex-col overflow-hidden md:min-h-[510px]`,
            data[currentPage]?.minHeight,
          ]}
        >
          <QuizForm
            quizData={data}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setSubmittedData={setSubmittedData}
            submittedData={submittedData}
            goToPage={goToPage}
          />
        </div>
      ) : currentPage === data.length ? (
        <QuizResult
          quizData={data}
          submittedData={submittedData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          goToPage={goToPage}
        />
      ) : currentPage === data.length + 1 ? (
        <QuizSendResultsForm
          quizData={data}
          submittedData={submittedData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          goToPage={goToPage}
          setCommunication={setCommunication}
        />
      ) : (
        <QuizThankYou consent communication={communication} />
      )}
    </>
  )
}

export default Quiz
