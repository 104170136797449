import React, { useEffect, useState } from "react"
import tw from "twin.macro"

// Components
import PrimaryCta from "@components/PrimaryCta"
import { isEDMode } from "@components/utils"

const QuizThankYou = ({ communication }) => {
  const [commsMessage, setCommsMessage] = useState(false)

  useEffect(() => {
    if (isEDMode) {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.get("communications") === "1") setCommsMessage(true)
    }
  }, [])

  return (
    <div tw="text-center">
      <h2
        css={[
          tw`font-cocon text-merlin font-bold text-[1.5625rem] leading-[1.25]`,
        ]}
      >
        Thank you, your email has been sent.
      </h2>

      <div tw="mb-10 lg:mb-[45px]">
        <p tw="text-base font-medium pt-2.5 mb-2.5 lg:(pt-4 mb-[18px])">
          Don&apos;t forget to bring your email results to your next
          doctor&apos;s appointment! In the meantime, if you&apos;re interested
          in learning more about XYWAV, start exploring.
        </p>
        <PrimaryCta url="/narcolepsy/lower-sodium-oxybate-treatment-option/">
          XYWAV AND SODIUM
        </PrimaryCta>
      </div>

      {(communication || commsMessage) && (
        <div>
          <h2 tw="font-cocon text-merlin font-bold text-[1.5625rem] leading-[1.25]">
            You&apos;re also signed up to receive communications about XYWAV.
          </h2>
          <p tw="text-base font-medium pt-2.5 lg:(text-xl pt-4)">
            Keep an eye out for helpful information about narcolepsy.
          </p>
          <p tw="text-base font-medium pt-2.5 mb-2.5 lg:(text-xl pt-6 mb-[18px])">
            Share some additional information about yourself so that we can send
            you relevant and helpful information.{" "}
          </p>
          <PrimaryCta url="https://cloud.updates.jazzpharma.com/gettingtoknowyou">
            TELL US ABOUT YOURSELF
          </PrimaryCta>
        </div>
      )}
    </div>
  )
}

export default QuizThankYou
