import React, { useLayoutEffect, useState } from "react"
import tw from "twin.macro"

import { groupDashedFocus } from "@styles/ui"
import { isBrowser, isEDMode } from "@components/utils"

import checkIcon from "@images/icons/quiz-check.svg"

const QuizResult = ({
  quizData,
  setCurrentPage,
  currentPage,
  submittedData,
  goToPage,
}) => {
  const buttonStyle = [
    tw`bg-cta-red text-base text-center inline-flex justify-center items-center uppercase font-extrabold text-white rounded-full py-4 px-8 transition-main cursor-pointer relative z-10 w-full min-h-[52px] focus-visible:outline-0 md:max-w-[350px] md:hover:(bg-merlin)`,
  ]

  const [checked, setChecked] = useState(false)

  useLayoutEffect(() => {
    //handle screenshots
    if (isBrowser) {
      if (isEDMode) {
        setChecked(true)
      }
    }
  }, [])

  return (
    <>
      <p
        css={[
          tw`text-center font-bold text-5xl leading-10 font-ffcocon text-studio`,
          tw`text-merlin`,
        ]}
      >
        Save your results!
      </p>
      <p tw="text-center mt-2">
        Your results may help you and your doctor discuss if starting on XYWAV
        could be right for you. Remember to bring it with you to help make the
        most of your next doctor&apos;s appointment.
      </p>

      <div tw="w-full flex flex-col md:(flex-row justify-center)">
        <button
          className="group email-btn"
          css={[buttonStyle, tw`my-5`]}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <div
            css={[tw`absolute w-full h-full top-0 left-0`, groupDashedFocus]}
          />
          Email My Results
        </button>
      </div>

      <p tw="text-center">Here&apos;s your personalized summary:</p>

      <div tw="md:(flex flex-col) lg:(mx-[100px])">
        {quizData.map((item, index) => {
          return (
            <div key={index}>
              <h3
                css={[
                  tw`font-black text-2xl text-studio my-4 lg:(text-3xl my-6)`,
                  tw`text-merlin`,
                ]}
              >
                {item.resultHeading}
              </h3>
              {index === 2 && (
                <p tw="text-[0.75rem] font-extrabold mb-4 lg:(mb-6)">
                  <sup>&dagger;</sup>XYWAV has 131 mg of sodium per maximum
                  recommended 9&#8209;g nightly dose.
                </p>
              )}
              {!submittedData[item.name].length && (
                <p tw="font-bold mb-1">None of the following applies to you:</p>
              )}
              <ul tw="text-sm md:text-xl">
                {item.options.map((option, optionIndex) => {
                  const isUserSelection =
                    submittedData[item.name] &&
                    submittedData[item.name].filter(
                      item => item === option.value
                    )[0]

                  return (
                    <li
                      css={[
                        tw`mb-3 font-light relative ml-7`,
                        isUserSelection && tw`font-bold`,
                      ]}
                      key={optionIndex}
                    >
                      {(isUserSelection || checked) && (
                        <img
                          src={checkIcon}
                          alt=""
                          tw="w-[21px] h-[16px] absolute -ml-7"
                        />
                      )}
                      {option.label || option.value}
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
        <div tw="w-full flex flex-col items-center mt-[30px] md:mt-10">
          <button
            className="group back-btn"
            css={[
              buttonStyle,
              tw`bg-white text-merlin border-2 border-merlin md:(hover:text-white)`,
            ]}
            onClick={() => goToPage(currentPage - 1)}
            type="button"
          >
            <div
              css={[tw`absolute w-full h-full top-0 left-0`, groupDashedFocus]}
            />
            Back
          </button>
        </div>
      </div>
    </>
  )
}

export default QuizResult
