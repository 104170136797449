import React from "react"
import tw from "twin.macro"

import checkIcon from "@images/icons/quiz-check-icon.svg"
import heartIcon from "@images/icons/quiz-heart-icon.svg"
import questionIcon from "@images/icons/quiz-question-icon.svg"

export const narcolepsyQuizData = [
  {
    name: "letsTalkSymptoms",
    question: <>Let&apos;s talk about your current narcolepsy&nbsp;symptoms:</>,
    instruction: <i>Select all that apply (optional).</i>,
    options: [
      {
        value: "I feel the uncontrollable need to sleep during the day.",
      },
      {
        value:
          "I experience random sleep episodes at unusual or unexpected times.",
      },
      {
        value:
          "When I feel a strong emotion, I experience a brief loss of muscle control (i.e., sagging jaw, drooping eyelid, head drop, slurred speech, weakness in the arm or shoulder, or buckling knees).",
      },
    ],
    icon: [
      {
        image: checkIcon,
        width: "200px",
        height: "200px",
      },
    ],
    resultHeading: "My current narcolepsy symptoms:",
  },
  {
    name: "cvHealth",
    question:
      "People with narcolepsy are at an increased risk of certain cardiovascular (CV) comorbidities. What would you like to discuss with your doctor about considering CV health?",
    instruction: <i>Select all that apply.*</i>,
    options: [
      {
        label: (
          <>
            I&apos;d like to know more about CV comorbidities I may be at higher
            risk for because I have narcolepsy.
          </>
        ),
        value:
          "I'd like to know more about CV comorbidities I may be at higher risk for because I have narcolepsy.",
        reset: [2],
      },
      {
        label: (
          <>
            I&apos;d like to learn about factors that may increase the risk of
            CV disease, such as excessive sodium intake.
          </>
        ),
        value:
          "I'd like to learn about factors that may increase the risk of CV disease, such as excessive sodium intake.",
        reset: [2],
      },
      {
        label: (
          <>
            I haven&apos;t considered talking to my doctor about heart health.
          </>
        ),
        value: "I haven't considered talking to my doctor about heart health.",
        reset: [0, 1],
      },
    ],
    isRequired: true,
    error: "You must make at least one selection to continue.",
    icon: [
      {
        image: heartIcon,
        width: "200px",
        height: "200px",
      },
    ],
    resultHeading: <>At my next doctor&apos;s appointment:</>,
    minHeight: tw`md:min-h-[650px]`,
  },
  {
    name: "questionsAboutXywav",
    question: (
      <>
        What questions do you have about XYWAV, the first and only low-sodium
        <sup>&dagger;</sup> oxybate treatment for cataplexy and/or EDS in
        narcolepsy?
        <span tw="block text-left text-[15px] leading-[20.49px] font-avenir font-medium mt-2.5">
          <sup>&dagger;</sup>XYWAV has 131 mg of sodium per maximum recommended
          9-g nightly dose.
        </span>
      </>
    ),
    instruction: <i>Select all that apply.*</i>,
    options: [
      {
        label: (
          <>
            If I&apos;m currently taking a stimulant or wake-promoting agent,
            what would adding XYWAV to my treatment plan look like?
          </>
        ),
        value:
          "If I'm currently taking a stimulant or wake-promoting agent, what would adding XYWAV to my treatment plan look like?",
        reset: [5],
      },
      {
        value:
          "How can I work with my doctor to tailor my treatment and find the dose that works best for me?",
        reset: [5],
      },
      {
        label: (
          <>
            How can choosing XYWAV over high&#x2011;sodium oxybates help reduce
            my cardiovascular risk from excessive sodium?
          </>
        ),
        value:
          "How can choosing XYWAV over high-sodium oxybates help reduce my cardiovascular risk from excessive sodium?",
        reset: [5],
      },
      {
        value: "What are the possible side effects of XYWAV?",
        reset: [5],
      },
      {
        label: (
          <>
            How can I access JazzCares<sup>&reg;</sup>?
          </>
        ),
        value: "How can I access JazzCares?",
        reset: [5],
      },
      {
        value: "None of the above.",
        reset: [0, 1, 2, 3, 4],
      },
    ],
    isRequired: true,
    error: "You must make at least one selection to continue.",
    icon: [
      {
        image: questionIcon,
        width: "200px",
        height: "200px",
      },
    ],
    resultHeading: (
      <>
        Here&apos;s what I want to ask about XYWAV, the first and only
        low&#8209;sodium<sup>&dagger;</sup> oxybate treatment for cataplexy
        and/or EDS in narcolepsy:
      </>
    ),
    minHeight: tw`md:min-h-[825px]`,
  },
]
